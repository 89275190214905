import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { ContentLayout } from 'components/layouts/ContentLayout';
import { Loader } from 'components/Loader';
import { DashboardStatisticContent } from '../components/statistic/dashboard/DashboardStatisticContent';
import { fetchDashboardStatistic } from '../api/fetchGeneralStatistic';

export const StatisticDashboardPage = () => {
    const [currentRegion, setCurrentRegion] = useState("");
    const { data, error, isLoading } = useQuery({
        queryKey: ['statistic/dashboard', [currentRegion]],
        queryFn: async () => {
            return await fetchDashboardStatistic(currentRegion)
        }
    });

    return (
        <ContentLayout title="Статистика: Дэшборд">
            {isLoading && <Loader />}

            {error && <div className="data-error">{error.message}</div>}

            {data && <DashboardStatisticContent data={data}
                                                currentRegion={currentRegion}
                                                setCurrentRegion={setCurrentRegion} />}
        </ContentLayout>
    );
};
