import styles from './DashboardStatisticContent.module.scss';
import { useState } from 'react';

/**
 * @param {Object} data
 * @param currentRegion
 * @param setCurrentRegion
 * @param refetchData
 * @param {Object} data.psychs
 * @param {Number} data.psychs.psychsToday
 * @param {Number} data.psychs.psychsLoginThisMonth
 * @param {Number} data.psychs.psychsLoginToday
 * @param {Number} data.psychs.newPsychs
 * @param {Number} data.psychs.newPsychsToday
 * @param {Number} data.psychs.activePsychs
 * @param {Number} data.psychs.activePsychsToday
 * @param {Number} data.psychs.bannedPsychs
 * @param {Number} data.psychs.bannedPsychsToday
 * @param {Number} data.psychs.pilotPsychs
 * @param {Number} data.psychs.pilotPsychsToday
 *
 * @param {Object} data.pupils
 * @param {Number} data.pupils.pupils
 * @param {Number} data.pupils.pupilsToday
 * @param {Number} data.pupils.pupilsLoginToday
 *
 * @param {Object} data.tests
 * @param {Number} data.tests[currentTimeframe]
 * @param {Number} data.tests.passed
 * @param {Number} data.tests.today
 *
 * @param {Object} data.alarms
 * @param {Number} data.alarms[currentTimeframe]
 * @param {Number} data.alarms.today
 *
 * @param {Object} data.advices
 * @param {Number} data.advices[currentTimeframe]
 * @param {Number} data.advices.today
 *
 * @param {Object} data.notes
 * @param {Number} data.notes[currentTimeframe]
 * @param {Number} data.notes.today
 *
 * @param {Object} data.meetings
 * @param {Number} data.meetings[currentTimeframe]
 * @param {Number} data.meetings.today
 *
 * @param {Object} data.tg
 * @param {Number} data.tg.psychsWithTgChat
 * @param {Number} data.tg.psychsTgChatToday
 * @param {Number} data.tg.pupilsWithTgChat
 * @param {Number} data.tg.pupilsTgChatToday
 *
 * @returns {JSX.Element}
 */
export const DashboardStatisticContent = ({ data, currentRegion, setCurrentRegion }) => {
    const [currentTimeframe, setCurrentTimeframe] = useState("total");
    const [isTFOpened, setIsTFOpened] = useState(false);
    const [isRGOpened, setIsRGOpened] = useState(false);

    const {
        psychs: {
            psychsTotal,
            psychsActive,
        },
        pupils: {
            pupilsTotal,
            pupilsWithTests,
            pupilsWithCategories
        },
        alarms,
        finishedMeetings,
        classes,
        advices,
        regions
    } = data;

    const changeTimeframe = (timeframe) => {
        setCurrentTimeframe(timeframe);
    }
    const changeRegion = async (regionName) => {
       setCurrentRegion(regionName);
    }
    const handleTFFilterOpen = () => {
        setIsRGOpened(false);
        setIsTFOpened(state => !state);
    }
    const handleRGFilterOpen = () => {
        setIsTFOpened(false);
        setIsRGOpened(state => !state);
    }

    return (
        <div className={styles.wrapper}>
            <button onClick={handleTFFilterOpen} tabIndex={0} className={styles.timeframeFilter}>
                <p>
                    {currentTimeframe === 'total' && 'За все время'}
                    {currentTimeframe === 'today' && 'За день'}
                    {currentTimeframe === 'week' && 'За неделю'}
                    {currentTimeframe === 'month' && 'За день'}
                    {currentTimeframe === 'year' && 'За год'}
                </p>
                {
                    isTFOpened && <div className={styles.timeframeFilterContent}>
                        <button onClick={() => changeTimeframe('total')}>Всего</button>
                        <button onClick={() => changeTimeframe('today')}>За день</button>
                        <button onClick={() => changeTimeframe('week')}>За неделю</button>
                        <button onClick={() => changeTimeframe('month')}>За месяц</button>
                        <button onClick={() => changeTimeframe('year')}>За год</button>
                    </div>
                }
            </button>
            <button style={{
                marginLeft: 10
            }} onClick={handleRGFilterOpen} tabIndex={0} className={styles.timeframeFilter}>
                <p>
                    {currentRegion || "Все регионы"}
                </p>
                {
                    isRGOpened && <div className={styles.timeframeFilterContent}>
                        <button onClick={() => changeRegion(null)}>{"Все регионы"}</button>
                        {
                            regions.map((item, index) => item && <button key={index} onClick={() => changeRegion(item)}>{item}</button>)
                        }
                    </div>
                }
            </button>
            <div className={styles.block}>
                <h4 className={styles.blockTitle}>Основное</h4>
                <div className={styles.inner}>
                    <p>
                        Кол-во подключенных психологов:{' '}
                        <span>
                            {psychsTotal[currentTimeframe]}
                        </span>
                    </p>
                    <p>
                        Кол-во активных психологов:{' '}
                        <span>
                            {psychsActive[currentTimeframe]}
                        </span>
                    </p>
                    <p>
                        Кол-во подключенных учеников:{' '}
                        <span>
                            {pupilsTotal[currentTimeframe]}
                        </span>
                    </p>
                    <p>
                        Кол-во запросов на разговор от детей к психологу:{' '}
                        <span>
                            {alarms[currentTimeframe]}
                        </span>
                    </p>
                    <p>
                        Кол-во проведённых встреч:{' '}
                        <span>
                            {finishedMeetings[currentTimeframe]}
                        </span>
                    </p>
                    <p>
                        Кол-во отправленных рекомендаций:{' '}
                        <span>
                            {advices[currentTimeframe]}
                        </span>
                    </p>
                    <p>
                        Кол-во созданных классов:{' '}
                        <span>
                            {classes[currentTimeframe]}
                        </span>
                    </p>
                </div>
            </div>
            <div className={styles.block}>
                <h4 className={styles.blockTitle}>Только по региону</h4>
                <div className={styles.inner}>
                    <p>
                        Кол-во учеников приняли участие в тестировании:{' '}
                        <span>
                            {pupilsWithTests.total}
                        </span>
                    </p>
                    <p>
                        Количество детей в целевых группах:{' '}
                        <span>
                            {pupilsWithCategories.total}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
};
